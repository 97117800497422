import React from "react"
import { Container } from "reactstrap"
import Footer from "./Footer.hbd"
import Header from "./Header"

function Layout({ children }) {
  return (
    <React.Fragment>
      <Header />
      <Container className="hbd-content-container main-main">{children}</Container>
      <Footer />
    </React.Fragment>
  )
}

export default Layout
